import { useCallback } from 'react';
import { v4 } from 'uuid';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PaymentMethod, PaymentSelectorProps } from './types';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import {
	FINIX_BUISNESS,
	FINIX_PAYMENT_METHOD,
	PAYMENT_OPTIONS,
} from './constant';
import { SelectedPaymentMethodState } from 'views/fund-investment/stores';

import './payment-select.scss';

export const PaymentSelector = ({
	onMethodSelect,
	error,
}: PaymentSelectorProps) => {
	const setSelectedPaymentMethod = useSetRecoilState(
		SelectedPaymentMethodState
	);

	const handleSelect = useCallback(
		(method: PaymentMethod) => {
			setSelectedPaymentMethod(method);
			onMethodSelect(method);
		},
		[onMethodSelect, setSelectedPaymentMethod]
	);

	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { businessId = '' } = sessionDetails ?? {};

	return (
		<div className="payment-selector">
			<div className="payment-selector__option--wrapper">
				{(FINIX_BUISNESS.includes(businessId ?? '')
					? FINIX_PAYMENT_METHOD
					: PAYMENT_OPTIONS
				).map(option => (
					<div
						key={v4()}
						onClick={() => handleSelect(option.id)}
						className="payment-selector__card"
					>
						<div className="payment-selector__icon">
							<i className={option.icon}></i>
						</div>
						<div className="payment-selector__right--section">
							<div className="payment-selector__card-heading--wrapper">
								<div className="payment-selector__card--heading">
									{option.label}
								</div>
								<div className="payment-selector__card--headarrow">
									<i className="ri-arrow-right-line"></i>
								</div>
							</div>
							<div className="payment-selector__label">
								{option.description}
							</div>
						</div>
					</div>
				))}
			</div>
			{error && (
				<div className="payment-selector__error" role="alert">
					{error}
				</div>
			)}
		</div>
	);
};
