import env from '@beam-australia/react-env';

export const API_HOST = env('API_HOST');

export const API_FACE_ENROLL = API_HOST + '/biometrics/face-enroll';

export const APIS = {
	KYCREQUESTS: '/kyc-requests',
	KYC_URL: '/kyc-url',
	KYC_REDIRECT: '/url/kyc-request-redirect',
	Countries: '/countries',
	StatesCountry: '/states?country=',
	KYC_SESSION: '/pipelines/sessions/',
	COMPLEX_SESSION: '/pipelines/complex-sessions/',
	KYC_ROTATE: '/kyc/rotate',
	CHECK_SESSION: '/pipelines/check-session/',
	PLAID_BALANCE: '/pipelines/balances/',
	REINVITE: '/pipelines/web-invite?code=',
	TRACK_SESSION_STATUS: '/pipelines/session-status?sessionId=',
	TRACK_COMPLEX_SESSION_STATUS: '/pipelines/session-status?nodeId=',
	REQUEST_TOKEN: '/pipelines/request-url',
	COMPLEX_REQUEST_TOKEN: '/pipelines/complex-request-url',
	DEVICE_TOKEN: '/pipelines/device-tokens',
	PLAID_PAY_AMOUNT: '/pipelines/session-payments',
	BACKUP_KYC: '/pipelines/backup-kyc',
	KYB_SUBMIT: '/pipelines/sessions/',
	TOKEN_EXCHANGE: '/pipelines/token-exchange',
	COMPLEX_SESSION_PAYMENT: '/pipelines/complex-session-payments',
	PIPELINE_QR: '/pipelines/pipeline-qr/',
	QR_INVITE: '/pipelines/qr-invite',
	ABORT_INVITE: '/pipelines/session-qr/',
	PERSONAL_DETAILS: '/ocr/images2',
	KYC_SUBMIT: '/pipelines/kyc',
	KYC_DOC_UPLOAD: '/pipelines/kyc/documents',
	PERSONAL_USER_DETAILS: '/qr',
	ENVELOPE_STATUS: '/esign/envelopes',
	ROTATE: '/rotate',
	IDV: '/idv',
	KYC_LEXIS_NEXIS: '/pipelines/kyc-lexis',
	KYC_OCR_IMAGE: '/kyc/ocr/image',
	DEVICE_TOKENS: '/device-tokens',
	PHONE_CODE: '/pipelines/phone-codes',
	VERIFY_CODE: '/pipelines/verify-phones',
	COMPLEX_QR: '/pipelines/complex-qrs',
	LINEAR_QR: '/pipelines/pipeline-qr',
	TOKEN: '/pipelines/tokens',
	KYC_VOUCHED: '/kyc/vouched-photo?id=',
	// THE KYB API's
	COMPANY_LIST_SEARCH: '/pipelines/kyb-search',
	COUNTRY_LIST: '/pipelines/kyb-countries',
	KYB_COMPANY_MEMBER: '/pipelines/kyb-members',
	// WEB RTC
	WEBRTC_CONNECTION: '/offer',
	KYB_SUBSIDIARY_BUSINESS: '/kyb/subsidiaries-list',
};

export const APIS_WITH_DOMAIN: { url: string }[] = [
	{
		url: APIS.COMPLEX_QR,
	},
	{
		url: APIS.PIPELINE_QR,
	},
];

export type ITokenApiType =
	| 'code'
	| 'update'
	| 'payment'
	| 'requestUrl'
	| 'status'
	| 'balance'
	| 'token-exchange'
	| 'verification'
	| 'kyc-verification'
	| 'abort-invite'
	| 'qr-invite'
	| 'pre-fill-representative-details'
	| 'kyc'
	| 'kyc-new'
	| 'device-tokens'
	| 'qr'
	| 'complex-session-nodes'
	| 'investor-invite'
	| 'metadata'
	| 'kyc-countries-documents'
	| 'save-nodes-draft'
	| 'get-nodes-draft'
	| 'ocr-scan';

export const API_TYPE: { [key: string]: ITokenApiType } = {
	CODE: 'code',
	UPDATE: 'update',
	PAYMENT: 'payment',
	REQUEST_URL: 'requestUrl',
	STATUS: 'status',
	BALANCE: 'balance',
	TOKEN_EXCHANGE: 'token-exchange',
	VERIFICATION: 'verification',
	ABORT: 'abort-invite',
	QR_INVITE: 'qr-invite',
	PREFILL_REPRESENTATIVE_DETAILS: 'pre-fill-representative-details',
	KYC: 'kyc-new',
	KYC_COUNTRY_DOCS: 'kyc-countries-documents',
	DEVICE_TOKEN: 'device-tokens',
	QR: 'qr',
	COMPLEX_SESSION_NODE: 'complex-session-nodes',
	INVESTOR_INVITE: 'investor-invite',
	METADATA: 'metadata',
	AutoSaveQuestionnaire: 'save-nodes-draft',
	AutoGetQuestionnaire: 'get-nodes-draft',
};

export const GOOGLE_API = {
	IP: 'https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate',
};

export const SaveNodesQuestionnaire = 'SURVEY_FORM';
