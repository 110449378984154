import { PaymentOption } from './types';

export const FINIX_SCRIPT = 'https://js.finix.com/v/1/finix.js';
export const FINIX_BUISNESS = [
	'65dc30fda548f97319a01655',
	'66886947685ac5750b32689a',
	'63a58474654ed953f4893753',
	'65d72029c887a486f8f2eb22',
	'65bb49af440af984c637597e',
	'6711f8d778791cdb5673df80',
];

export const PAYMENT_OPTIONS: PaymentOption[] = [
	{
		id: 'plaid',
		label: 'Plaid',
		icon: 'ri-bank-line',
		description:
			'Easy and secure electronic transfer directly from your bank account.',
	},
];

export const FINIX_PAYMENT_METHOD: PaymentOption[] = [
	{
		id: 'card',
		label: 'Debit Card',
		icon: 'ri-bank-card-line',
		description:
			'Fast and convenient, using your debit card for immediate payment.',
	},
	{
		id: 'card',
		label: 'Credit Card',
		icon: 'ri-bank-card-line',
		description:
			'Fast and convenient, using your debit card for immediate payment.',
	},
	{
		id: 'ach',
		label: 'ACH',
		icon: 'ri-bank-line',
		description:
			'Easy and secure electronic transfer directly from your bank account.',
	},
];
